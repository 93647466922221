import { Injectable, inject } from '@angular/core';
import { Logger } from '@app/@shared';
import { TranslocoService } from '@ngneat/transloco';
import { RoomHeightLimitService } from './room-height-limit.service';
import { GlobaltoastService, TranslationPopup } from '@app/core/services/GlobalToast/globaltoast.service';
const log = new Logger('RoomHeightLimitsWarningService');
@Injectable({
  providedIn: 'root',
})
export class RoomHeightLimitsWarningService {
  private translateService = inject(TranslocoService);
  private roomHeightLimitsService = inject(RoomHeightLimitService);
  private _globalToastService = inject(GlobaltoastService);

  showWarningIfOutOfLimit(realEstateContainer: any) {
    // first destroy element
    let element = 'btnRoomHeight';
    this.showToast(element, '');
    if (realEstateContainer && realEstateContainer.usages) {
      //var element = '#realEstateContainer\\.geometry\\.roomHeight\\.input';
      /* if (element !== undefined && typeof element !== 'undefined' && $(element).hasClass("tooltipstered")) {
            $(element).tooltipster('close');
        } */

      var isValid = true;
      var mapping = this.roomHeightLimitsService.getMapping();
      var q0 = 0;
      var q100 = 0;
      var weightsSum = 0;
      realEstateContainer.usages.forEach((usage: any) => {
        var container = mapping[usage['type']];
        if (container) {
          if (usage['type'] && usage['type'] != null && usage['percentage'] && usage['percentage'] != null) {
            q0 += Number(usage['percentage']) * container[0];
            q100 += Number(usage['percentage']) * container[2];
            weightsSum += Number(usage['percentage']);
          } else {
            q0 = container[0] * 100;
            q100 = container[2] * 100;
            if (weightsSum != 0) {
              isValid = false;
            }
            weightsSum = 100;
          }
        }
      });

      if (isValid && weightsSum > 97 && weightsSum < 103) {
        var textBase: string = '';

        if (realEstateContainer.geometry && Number(realEstateContainer.geometry.roomHeight) < q0 / weightsSum) {
          textBase = 'processform.roomHeightLimit.low.bp';
        } else if (
          realEstateContainer.geometry &&
          Number(realEstateContainer.geometry.roomHeight) > q100 / weightsSum
        ) {
          textBase = 'processform.roomHeightLimit.high.bp';
        }
        if (textBase) {
          var textStart = this.translateService.translate(textBase + '.start');
          var text0 = this.translateService.translate(textBase + '.0');
          var text1 = this.translateService.translate(textBase + '.1');
          var text2 = this.translateService.translate(textBase + '.2');
          var text =
            '<div>' +
            '<label>' +
            textStart +
            '</label>' +
            "<div><span style='display:flex'><label class='clearLabel'>&bull;&nbsp;</label><label class='clearLabel'>" +
            text0 +
            '</label></span></div>' +
            "<div><span style='display:flex'><label class='clearLabel'>&bull;&nbsp;</label><label class='clearLabel'>" +
            text1 +
            '</label></span></div>' +
            "<div><span style='display:flex'><label class='clearLabel'>&bull;&nbsp;</label><label class='clearLabel'>" +
            text2 +
            '</label></span></div>' +
            '</div>';
          this.showToast(element, text);
          //return text;
        }
      }
    }
  }

  /**
   * show toast, similar to usage change
   * @param element
   * @param text
   */
  showToast(element: string, translation: string) {
    // if ($(element).hasClass("tooltipstered")) {
    //     var instance = $(element).tooltipster('instance');
    //     instance.content(text);
    //     instance.open();
    // } else {
    //     setTimeout(function () {
    //         $(element).tooltipster({
    //             contentAsHTML: true,
    //             content: text,
    //             theme: 'tooltipster-kw',
    //             side: ['right'],
    //             zIndex: 1048, // same as validation messages
    //             maxWidth: 430,
    //             trigger: 'custom',
    //             plugins: ['sideTip'],
    //             interactive: true,
    //             repositionOnScroll: true,
    //             functionReady: function (instance:any, helper:any) {
    //                 $(helper.tooltip).click(function () {
    //                     if (element !== undefined && typeof element !== 'undefined' && $(element).hasClass("tooltipstered")) {
    //                         $(element).tooltipster('close');
    //                     }
    //                 });
    //                 $(helper.origin).click(function () {
    //                     if (element !== undefined && typeof element !== 'undefined' && $(element).hasClass("tooltipstered")) {
    //                         $(element).tooltipster('close');
    //                     }
    //                 });
    //             }
    //         }).tooltipster('open');
    //     });
    if (translation === '') {
      this._globalToastService.translation$.next({
        translation: translation,
        location: element,
        showToast: false,
      } as TranslationPopup);
    } else {
      this._globalToastService.translation$.next({
        translation: translation,
        location: element,
        showToast: true,
      } as TranslationPopup);
    }
  }
}
